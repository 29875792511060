import React, { useState, useEffect, useContext, createContext } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle, DialogActions, Stack, Button, IconButton, FormControl, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useValidation, NOT_EMPTY_VALIDATOR } from "./validation";


const ModalContext = createContext();

export const useModal = () => {
    return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
    const [title, setTitle] = useState(null);
    const [component, setComponent] = useState(null);
    const [options, setOptions] = useState(null);
    const [onClose, setOnClose] = useState(() => { });

    function openModal(title, component, onClose, options) {
        setTitle(title);
        setComponent(component);
        setOptions(options);
        if (onClose)
            setOnClose(() => onClose);
    }

    function closeModal() {
        options?.closeModal && options.closeModal();
        setComponent(null);
    }

    return (<ModalContext.Provider value={{ openModal, closeModal, title, component, options, onClose }}>
        {children}
        <GenericModal />
    </ModalContext.Provider>);
};


const GenericModal = () => {
    const { title, component, closeModal, options } = useContext(ModalContext);

    useEffect(() => {
        const handleKeyUp = (e) => {
            if (e.key === "Escape") {
                closeModal();
            }
        };

        document.addEventListener("keyup", handleKeyUp);

        return () => {
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, [closeModal]);

    if (!component) return null;

    return (<Dialog
        open={true}
        onClose={closeModal}
        fullScreen={options?.fullScreen && options?.maxWidth != "xs"}
        fullWidth={options?.fullWidth === undefined || options?.fullWidth}
        maxWidth={options?.maxWidth || "sm"}
    >
        {title
            ? <DialogTitle sx={{ padding: "16px 16px 0 16px" }}>
                <Stack direction="row" alignItems="center">
                    {title}
                    <IconButton
                        data-cy="btn-dialog-close"
                        onClick={closeModal}
                        sx={{ marginLeft: "auto" }}
                        edge="end"
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle >
            : (title !== null || null) && (<IconButton onClick={closeModal} sx={{ position: "absolute", right: 10 }} edge="end">
                <CloseIcon />
            </IconButton>)
        }
        {component}
    </Dialog>);
};

export const useConfirm = () => {
    const { openModal } = useModal();

    const showModal = (message, onConfirm, options) => {
        openModal(
            options?.title,
            <ConfirmModal />,
            onConfirm,
            { ...options, message, maxWidth: "xs" }
        );
    };

    return showModal;
};


const ConfirmModal = () => {
    const { closeModal, onClose, options: { message, confirmAction } } = useModal();
    const { t } = useTranslation();

    function handleConfirm() {
        onClose();
        closeModal();
    }

    return (<>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
            <Button
                size="small"
                variant={onClose ? "outlined" : "contained"}
                onClick={closeModal}
                data-cy="btn-confirm-cancel">
                {t(onClose ? "shared:btn-cancel" : confirmAction || "shared:btn-ok")}
            </Button>
            {onClose && <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                data-cy="btn-confirm-ok">
                {confirmAction || t("shared:btn-confirm")}
            </Button>}
        </DialogActions>
    </>);
};

export const usePrompt = () => {
    const { openModal } = useModal();

    return (title, onPrompt, options) => {
        openModal(
            title,
            <PromptModal />,
            onPrompt,
            { ...options, maxWidth: "xs" }
        );
    };
};

const PromptModal = () => {
    const { closeModal, onClose, options: { validator, defaultValue, onCancel, label, action } } = useModal();
    const { t } = useTranslation();
    const validation = useValidation({ value: { validator: validator || NOT_EMPTY_VALIDATOR } });
    const [promptValue, setPromptValue] = useState({ value: defaultValue || "" });

    useEffect(() => validation.init(promptValue), []);

    function handleValueChange(e) {
        const value = validation.validate("value", e.target.value);
        if (value === undefined) return;
        setPromptValue({ value });
    };

    function handleCancel() {
        onCancel && onCancel();
        closeModal();
    }

    function handleSave() {
        onClose(promptValue.value);
        closeModal();
    };

    return (<>
        <DialogContent dividers>
            <FormControl fullWidth>
                <TextField
                    data-cy="input-prompt"
                    margin="dense"
                    variant="standard"
                    label={label || "enter"}
                    value={promptValue.value}
                    error={!validation.isValid("value")}
                    onChange={handleValueChange}
                    autoComplete="off"
                    autoFocus
                />
            </FormControl>
        </DialogContent>

        <DialogActions>
            <Button
                variant="outlined"
                data-cy="btn-prompt-cancel"
                onClick={handleCancel}
            >
                {t("shared:btn-cancel")}
            </Button>
            <Button
                variant="contained"
                data-cy="btn-prompt-ok"
                onClick={handleSave}
                disabled={!validation.isValid()}
            >
                {action || t("shared:btn-save")}
            </Button>
        </DialogActions>
    </>);
};
