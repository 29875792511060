import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack, IconButton, Alert, Tooltip } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { SelfEditTextField, CopyRefButton } from "../../widgets";
import { FarmMap } from "./widgets/map";
import { DrawFieldControls, ImportFilesControl, ImportSessionControl } from "./widgets/map/editControls";
import { useConfirm, usePrompt } from "../../hooks/modal";
import { displayErrorMessage } from "../../redux/messages";
import { useFarmApi } from "../hooks";
import { updateField, deleteField, setSelectedFarmImports } from "../redux/reducer";
import { selectFarm, selectEditFields, selectImportStatus } from "../redux/selectors";

const OPTIONS_IMMUTABLE = { weight: 1, color: "#000080", fillOpacity: 0.3, backgroundColor: "#000080" };
const OPTIONS_IMPORTED = { weight: 3, color: "#3388ff", fillOpacity: 0.3, backgroundColor: "#3388ff", mutable: true };
const OPTIONS_ERROR = { weight: 3, color: "red", fillOpacity: 0.3, backgroundColor: "red" };

export const FarmEditor = ({ fieldInfo }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const farmApi = useFarmApi();
    const farm = useSelector(selectFarm);
    const editFields = useSelector(selectEditFields);
    const importStatus = useSelector(selectImportStatus);
    const farmMapRef = useRef();
    const editMode = useRef();
    const selectedField = useRef(null);
    const [_, setForceUpdate] = useState(0);

    const { fields, fieldOptions } = useMemo(() => {
        if (!editFields?.length) return { fields: editFields, fieldOptions: {} };

        const withBoundaries = editFields.filter(f => {
            if (Boolean(f.boundaries) && f.id === selectedField.current?.field?.id) {
                selectedField.current.field = f;
            }
            return Boolean(f.boundaries);
        });

        const fieldOptions = {};
        withBoundaries.forEach(field => {
            fieldOptions[field.id] = field.status
                ? field.status === "OK"
                    ? OPTIONS_IMPORTED
                    : OPTIONS_ERROR
                : OPTIONS_IMMUTABLE;
        });
        return { fields: withBoundaries, fieldOptions }
    }, [farm?.id, farm?.ts, importStatus?.ts]);

    const farmLocation = farm.location ? { lat: farm.location.coordinates[1], lng: farm.location.coordinates[0] } : { lat: 47.470520, lng: 8.474440 };

    useEffect(() => {
        if (fields && !fields.length) {
            const bounds = [[farmLocation.lat - 0.005, farmLocation.lng - 0.005], [farmLocation.lat + 0.005, farmLocation.lng + 0.005]];
            farmMapRef.current.computeBounds(bounds);
        }
    }, [farm?.id, farm?.ts, fields?.length]);

    if (fields == null) return;

    const handleChangeMode = (mode) => {
        editMode.current = mode;
    }

    function handleFieldClick(fieldId) {
        if (editMode.current)
            return false;
        const selected = selectedField.current?.field;
        if (!fieldId && !selected || fieldId === selected?.id) {
            return false;
        }
        const field = fieldId && fields.find(f => f.id === fieldId);
        selectedField.current = fieldId ? { field } : null;
        setForceUpdate(forceUpdate => forceUpdate + 1);
        return false;
    }

    function handleCreate(feature) {
        farmApi.createImportField(feature, farm.id, () => {
            // Give a chance to the async job to complete
            setTimeout(() => {
                dispatch(setSelectedFarmImports(undefined));
            }, 500);
        });
    }

    function handleUpdate(feature) {
        if (feature) {
            const boundaries = feature.geometry;
            farmApi.patchImportField(selectedField.current.field.id, { boundaries }, farm.id, () => {
                dispatch(setSelectedFarmImports(undefined));
            });
        } else {
            dispatch(displayErrorMessage("Invalid field shape"));
            dispatch(setSelectedFarmImports(null));
            // Hack to force reload of fields
            setTimeout(() => {
                dispatch(setSelectedFarmImports(undefined));
            }, 200);
        }
    }

    function handleDelete(field) {
        selectedField.current = null;
        setForceUpdate(forceUpdate => forceUpdate + 1);
    }

    return (<Stack direction="row" style={{ width: "100%" }}>
        <FarmMap
            ref={farmMapRef}
            defaultLocation={farmLocation}
            controls={<>
                <ImportFilesControl farmId={farm.id} status={importStatus?.status} />
                <ImportSessionControl farmId={farm.id} status={importStatus?.status} />
                <DrawFieldControls
                    selectedField={selectedField.current?.field}
                    onCreate={handleCreate}
                    onUpdate={handleUpdate}
                    onChangeMode={handleChangeMode}
                />
            </>}
            farm={farm}
            fields={fields}
            fieldsHash={importStatus?.ts}
            fieldOptions={fieldOptions}
            onFieldClick={handleFieldClick}
            selectedFields={selectedField.current ? [selectedField.current.field.id] : null}
        />
        {(selectedField.current || null) && (<Stack sx={{ width: "400px", padding: 1 }}>
            <FieldPanel field={selectedField.current.field} farm={farm} onDelete={handleDelete} />
            {(fieldInfo || null) && fieldInfo(selectedField.current.field?.id)}
        </Stack>)}
    </Stack>);
};

const FieldPanel = ({ field, farm, onDelete }) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const prompt = usePrompt();
    const dispatch = useDispatch();
    const farmApi = useFarmApi();

    if (!field) return null;

    function handleDeleteField() {
        if (field.status) {
            confirm(
                t("shared:farms.message-confirm-remove-import-field"),
                () => {
                    farmApi.removeImportField(field.id, farm.id, () => {
                        onDelete && onDelete();
                        //hack to force redraw
                        dispatch(setSelectedFarmImports(null));
                        setTimeout(() => {
                            dispatch(setSelectedFarmImports(undefined));
                        }, 200);
                    });
                }
            );
        } else {
            const validator = (v) => (v === t("shared:label-confirm-delete")) ? null : "";
            prompt(
                t("shared:farms.message-confirm-delete-field"),
                () => {
                    farmApi.deleteField(field.id, () => {
                        onDelete && onDelete(field);
                        dispatch(deleteField(field.id))
                    });
                },
                { label: t("shared:farms.text-confirm-delete-farm"), action: t("shared:btn-delete"), validator }
            );
        }
    }

    function handleFieldNameChange(name) {
        if (field.status) {
            farmApi.patchImportField(field.id, { name }, farm.id, () => dispatch(setSelectedFarmImports(undefined)));
        } else {
            farmApi.patchField(field.id, { name }, (field) => {
                dispatch(updateField(field))
            });
        }
    }

    return (<Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ width: "100%" }}>
                <SelfEditTextField
                    multiline
                    fullWidth
                    value={field.name} onSave={handleFieldNameChange}
                    helperText={<span>{t("shared:farms.title-field-area", { area: field.area })} <CopyRefButton info={field.id} /></span>}
                />
            <IconButton size="small" edge="end" onClick={handleDeleteField}><DeleteForeverOutlinedIcon /></IconButton>
        </Stack>
        {(field.status === "OK" || null) && (<Alert severity="success">
            {t("shared:farms.import.field-status.OK")}
        </Alert>
        )}
        {(field.status && field.status !== "OK" || null) && (<Alert severity="error">
            {t(`shared:farms.import.field-status.${field.status}`)}
            {field.unprocessable_error}
        </Alert>)}
    </Stack>);
};
