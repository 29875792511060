import { useState, useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMap } from "react-leaflet";
import { useTranslation } from "react-i18next";
import { DialogContent, ButtonGroup, Button, Tooltip, Alert, Menu, MenuItem, Typography, Stack } from "@mui/material";
import ImportFilesIcon from "@mui/icons-material/CloudUploadOutlined";
import ImportSessionIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { useLoading } from "../../../../loadingContext";
import { useModal } from "../../../../hooks/modal";
import { useFarmApi } from "../../../hooks";
import { displayInfoMessage, displayErrorMessage } from "../../../../redux/messages";
import { selectEditFields } from "../../../redux/selectors";
import { setFarms, setSelectedFarmImports } from "../../../redux/reducer";
import { MapButton } from "./tools";


export const DrawFieldControls = ({ options = {}, onCreate, onUpdate, selectedField, onChangeMode }) => {
    const { t } = useTranslation();
    const mode = useRef(false);
    const map = useMap();

    useEffect(() => {
        // Global options for Geoman
        map.pm.setGlobalOptions({
            allowSelfIntersection: false, // Disallow polygon intersections
            measureArea: true,           // Show area measurement while drawing
            ...options,                  // Merge additional user options
        });

        // Add Geoman controls to the map
        map.pm.addControls({
            position: "topleft",
            drawText: false,
            drawMarker: false,
            drawPolygon: Boolean(onCreate),
            drawPolyline: false,
            drawCircle: false,
            drawCircleMarker: false,
            drawRectangle: false,
            editMode: Boolean(selectedField?.status),
            editPolygon: Boolean(selectedField?.status),
            dragMode: false,
            cutPolygon: Boolean(selectedField?.status),
            removalMode: false,
            rotateMode: false
        });
        map.pm.setLang('agricircle', t("shared:farms.geoman", { returnObjects: true }), 'en');

        const toggleMode = (newMode) => {
            mode.current = newMode;
            onChangeMode && onChangeMode(newMode);
        };

        const handlePmModeChange = (e) => {
            const eventType = e.type;

            let mode;
            // Check if the event type corresponds to a mode start (enable mode) or end (disable mode)
            if (eventType.includes("start")) {
                mode = true;
            } else if (eventType.includes("end")) {
                mode = false;
            } else return;
            toggleMode(mode);
        };

        map.on("pm:globaleditmodetoggled", (e) => toggleMode(e.enabled));  // When the "Edit Layers" tool is clicked and enabled
        map.on("pm:drawstart", handlePmModeChange);
        map.on("pm:drawend", handlePmModeChange);
        map.on("pm:editstart", handlePmModeChange);
        map.on("pm:editend", handlePmModeChange);
        if (onCreate)
            map.on("pm:create", (e) => onCreate(e.layer.toGeoJSON()));

        let fieldsFeatureGroup = null;
        if (onUpdate) {
            map.eachLayer((layer) => {
                if (layer instanceof L.Polygon) {
                    layer.options.pmIgnore = !Boolean(selectedField?.id) || layer?.feature?.properties?.id !== selectedField?.id;
                } else if ((layer instanceof L.FeatureGroup) && (layer.options.id === "fields"))
                    fieldsFeatureGroup = layer;
            });
            fieldsFeatureGroup.on("pm:update", (e, v) => onUpdate(e.layer.toGeoJSON()));
            fieldsFeatureGroup.on("pm:cut", (e, v) => {
                const { layer } = e;
                const feature = layer.toGeoJSON();
                if (feature.geometry.type !== "Polygon") {
                    map.removeLayer(layer);
                    onUpdate();
                } else {
                    onUpdate(feature);
                }
            });
        }

        return () => {
            // Cleanup Geoman controls and event listeners on unmount
            map.off("pm:globaleditmodetoggled");
            map.off("pm:drawstart");
            map.off("pm:drawend");
            map.off("pm:editstart");
            map.off("pm:editend");
            if (onCreate)
                map.off("pm:create");
            if (fieldsFeatureGroup) {
                fieldsFeatureGroup.off("pm:update");
                fieldsFeatureGroup.off("pm:cut");
            }
            map.pm.removeControls();
        };
    }, [selectedField?.id]);

    return null; // This component doesn"t render anything visually
};


export const ImportFilesControl = ({ farmId, status }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const farmApi = useFarmApi();
    const fileInputRef = useRef();
    const { startLoading, doneLoading } = useLoading();

    function handleClick(e) {
        if (status === "in_progress") {
            farmApi.listImportFields(farmId, (data, inProgress) => {
                if (inProgress) {
                    if (e.shiftKey)
                        farmApi.resetImport(farmId, () => {
                            dispatch(displayInfoMessage(t("shared:farms.import.text-import-canceled")));
                            dispatch(setSelectedFarmImports(null));
                        });
                    else
                        dispatch(displayInfoMessage(t("shared:farms.import.text-still-in-progress"), data));
                } else {
                    dispatch(setSelectedFarmImports(data));
                }
            });
        } else {
            fileInputRef.current.click();
        }
    }

    function handleUploadFiles(event) {
        const files = Array.from(event.target.files);
        if (!files?.length) return;

        const formData = new FormData();
        files.forEach(file => {
            formData.append("files", file);
        });
        formData.append("farm_id", farmId);
        formData.append("overlap_action", "update");
        farmApi.startImport(farmId, formData, () => {
            dispatch(setSelectedFarmImports({ farm_id: farmId }));
            startLoading();
            setTimeout(async () => {
                await farmApi.listImportFields(farmId, (data) => {
                    if (!data) {
                        dispatch(displayErrorMessage(t("shared:farms.import.text-no-importable-fields")));
                    } else if (data.error || data.errors || data.fields) {
                        dispatch(setSelectedFarmImports(data));
                    }
                });
                doneLoading();
            }, 1500);
        });
    }


    return (<Tooltip placement="right" title={t("shared:farms.import." + (status === "in_progress" ? "title-in-progress" : "title-import"))}>
        <MapButton data-cy="btn-import-fields" onClick={handleClick}>
            <ImportFilesIcon sx={status === "in_progress" ? spinAnimation : undefined} />
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".dbf,.shp,.shx,.prj,.geojson,.json,.kml"
                multiple
                onChange={handleUploadFiles}
            />
        </MapButton>
    </Tooltip>);
};

const spinAnimation = {
    animation: "acSpin 10s linear infinite",
    "@keyframes acSpin": {
        "from": {
            transform: "rotateY(0deg)",
        },
        "to": {
            transform: "rotateY(360deg)",
        },
    }
};



export const ImportSessionControl = ({ farmId, status }) => {
    const { t } = useTranslation();
    const { openModal } = useModal();
    const sessionInProgress = status === "error" || status === "review";

    function handleManageImportSession() {
        openModal(
            t("shared:farms.import.title-review"),
            <FinalizeImport farmId={farmId} />,
            null,
            { maxWidth: "sm", fullWidth: false }
        );
    }

    return (<Tooltip placement="right" title={t("shared:farms.import.title-review")}>
        <span>
            <MapButton
                data-cy="btn-import-fields"
                disabled={!sessionInProgress}
                onClick={handleManageImportSession}>
                <ImportSessionIcon />
            </MapButton>
        </span>
    </Tooltip>);
};

const FinalizeImport = ({ farmId }) => {
    const { t } = useTranslation();
    const { closeModal } = useModal();
    const dispatch = useDispatch();
    const farmApi = useFarmApi();
    const fields = useSelector(selectEditFields);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const { area, fieldCount, fieldProperties } = useMemo(() => {
        if (!fields?.length) return { area: 0, fieldCount: 0, fieldProperties: null };
        // the occurrences object keeps track of the number of occurrences of non-numerical properties
        const occurrences = {};
        let area = 0;
        let fieldCount = 0;
        for (const f of fields) {
            if (f.status != "OK") continue;
            fieldCount++;
            area += f.area;
            if (f.properties)
                Object.entries(f.properties).forEach(([p, v]) => {
                    if (occurrences[p] == undefined)
                        occurrences[p] = { count: 0, values: new Set() };
                    if (!occurrences[p].values.has(v)) {
                        occurrences[p].values.add(v);
                        occurrences[p].count++;
                    }
                });
        }
        // keep all properties that appear in at least half the fields
        const minOccurrences = fieldCount / 2;
        const fieldProperties = [];
        Object.entries(occurrences).forEach(([p, occurrence]) => { if (occurrence.count >= minOccurrences) fieldProperties.push({ name: p, count: occurrence.count }); });
        fieldProperties.sort((a, b) => b.count - a.count);
        return { area, fieldCount, fieldProperties };
    }, [Boolean(fields)]);

    function handleActivateAllAction() {
        closeModal();
        farmApi.activateImportFields(null, farmId, () => {
            dispatch(setFarms(null));
            dispatch(setSelectedFarmImports(null));
        });
    }

    function handleClearImportAction() {
        closeModal();
        farmApi.resetImport(farmId, () => dispatch(setSelectedFarmImports(null)));
    }

    function handleMenuOpen(e) {
        if (!menuAnchorEl)
            setMenuAnchorEl(e.currentTarget);
    }

    function handleMenuClose() {
        if (menuAnchorEl)
            setMenuAnchorEl(null);
    }

    function handleBatchRename(renameToProp) {
        handleMenuClose();
        farmApi.renameImportFields(renameToProp, farmId, (data) => {
            dispatch(displayInfoMessage(t("shared:farms.import.text-batch-rename-successful")));
            dispatch(setSelectedFarmImports(data));
            closeModal();
        });
    }

    return (<DialogContent>
        {(area === 0 || null) && <Alert severity="error" sx={{ marginBottom: 1 }}>
            <Typography>{t("shared:farms.import.text-no-importable-fields")}</Typography>
            <FieldErrors fields={fields} />
        </Alert>}
        {(fieldCount || null) && <Typography variant="body1">
            {t("shared:farms.import.text-importable-fields", { count: fieldCount })}
        </Typography>}
        <ButtonGroup sx={{ marginTop: 1 }}>
            {(Boolean(area) && fieldProperties?.length || null) && <Button
                size="small"
                variant="outlined"
                data-cy="btn-import-batch-rename"
                onClick={handleMenuOpen}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {t("shared:farms.import.btn-batch-rename")}
            </Button>}
            <Button
                size="small"
                variant="outlined"
                data-cy="btn-import-reset"
                onClick={handleClearImportAction}
            >
                {t("shared:farms.import.btn-clear")}
            </Button>
            {(Boolean(area) || null) && <Button
                size="small"
                variant="contained"
                data-cy="btn-import-activate-all"
                onClick={handleActivateAllAction}
            >
                {t("shared:farms.import.btn-activate-all")}
            </Button>}
        </ButtonGroup>
        {(fieldProperties?.length || null) && <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem disabled>{t("shared:farms.import.text-batch-rename")}</MenuItem>
            {fieldProperties.map(p => <MenuItem
                key={`prop-${p.name}`}
                data-cy={`menu-import-prop-${p.name}`}
                onClick={() => handleBatchRename(p.name)}
            >{p.name} ({p.count})
            </MenuItem>)}
        </Menu>}
    </DialogContent>);
};

const FieldErrors = ({ fields }) => {
    if (!fields?.length) return null;
    const errorFields = fields.filter(f => f.status && f.status !== "OK");
    if (!errorFields.length) return null;
    return (<Stack>
        {errorFields.map(f => <div key={`err${f.id}`}>{f.name} - {f.status}</div>)}
    </Stack>);
};
